import React, {Fragment} from 'react';
import {rgba} from 'polished';
import styled, {css} from 'styled-components';
import map from 'lodash/map';
import ButtonBase from 'components/button/button';
import auth from 'utils/auth';
import {HERO_COLOR as color, HEADER_BG_COLOR as bgColor} from 'assets/theme';
import {getLocation} from 'utils';

const height = '64px';

const Button = styled(ButtonBase)`
  color: ${color};
  background-color: ${bgColor};
  display: inline-block;
  font-size: 16px;
  font-weight: normal;
  height: ${height};
  line-height: ${height};
  padding: 0 12px;
  text-align: center;
  cursor: pointer;
  min-width: 48px;
  white-space: nowrap;
  border: none;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  transition: 0.2s linear;
  position: relative;
  z-index: 991;

  &:focus,
  &:hover {
    outline: 0;
    color: ${color};
    border-top-color: ${color};
  }

  ${props =>
    props.selected &&
    css`
      border-top-color: ${color};
    `};
`;
const Root = styled.header`
  padding: 0 18px;
  height: ${height};
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 995;
  transition: margin-left 0.3s ease;
  background-color: ${bgColor};
  box-shadow: 4px 0 5px rgba(0, 0, 0, 0.2);
`;
const Container = styled.div.attrs({className: 'container'})`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const NavLeft = styled.div`
  display: flex;
  align-items: center;
`;
const NavRight = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
`;
const NavDivider = styled.div`
  border-left: 1px solid ${rgba(color, 0.7)};
  height: 20px;
  align-self: center;
  margin: 0 12px;
`;

const routes = {
  Home: '/',
  About: '/about',
  Blog: '/blog',
  Contact: '/contact',
  // Demo: '/demo',
};

const StaticNavbar = () => {
  return (
    <Root role="navigation">
      <Container>
        <NavLeft>
          {map(routes, (to, label) => (
            <Fragment key={label}>
              <Button to={to} selected={getLocation().pathname === to}>
                {label}
              </Button>
              <NavDivider className="d-none d-md-block" />
            </Fragment>
          ))}
        </NavLeft>
        <NavRight>
          <Button handleClick={auth.login}>Signup/Login</Button>
          <NavDivider />
        </NavRight>
      </Container>
    </Root>
  );
};

export default StaticNavbar;
