import React from 'react';
import PropTypes from 'prop-types';
import {ThemeProvider} from 'styled-components';
import StaticNavbar from 'components/nav/static-navbar';
import PublicFooter from 'components/footer/public-footer';
import Notifications from 'components/notifications/notifications';
import theme from 'assets/theme';
import SEO from 'components/seo';
import styled from 'styled-components';
import {getLocation} from 'utils';
import {backgroundColor} from 'components/wrappers';

export const Root = styled.div`
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: ${props => props.theme.nav.height};
  transition: margin-left 0.3s ease-out;
  ${backgroundColor};
`;
export const Main = styled.main`
  flex: 1 1 auto;
  overflow: auto;
`;
const Content = styled.div`
  ${props => props.contentPadding && 'padding: 30px 0;'};
`;

const Layout = ({location, children, container, seo, contentPadding, bgColor}) => {
  const rootPath = `${__PATH_PREFIX__}/`;
  const currentPath = (location && location.pathname) || getLocation().pathname;

  return (
    <ThemeProvider theme={theme}>
      <Root bgColor={bgColor}>
        <SEO title={seo.title} description={seo.description} />
        <StaticNavbar
          {...{
            currentPath,
            rootPath,
            isStaticPage: true,
          }}
        />
        <Notifications />
        <Main className={container ? 'container' : ''}>
          <Content contentPadding={contentPadding}>{children}</Content>
        </Main>
        <PublicFooter />
      </Root>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
    .isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  container: PropTypes.bool,
  contentPadding: PropTypes.bool,
  isStaticPage: PropTypes.bool,
  pageColor: PropTypes.string,
  seo: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
};

Layout.defaultProps = {
  contentPadding: false,
  container: false,
};

export default Layout;
