import React, {Fragment} from 'react';

const year = new Date().getFullYear();
const Copyright = ({small}) => {
  if (!small) {
    return (
      <Fragment>
        Copyright © {new Date().getFullYear()}{' '}
        <a href="http://bearjs.co.uk">BearJS Ltd</a>. All rights reserved.
      </Fragment>
    );
  }
  return (
    <small>
      Copyright © {year} <a href="http://bearjs.co.uk">BearJS Ltd</a>. All rights
      reserved.
    </small>
  );
};

export default Copyright;
