import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import map from 'lodash/map';
import isArray from 'lodash/isArray';
import styled from 'styled-components';
import {actions} from 'state/notifications';
import {getLocation} from 'utils';

const NotificationsContainer = styled.div`
  position: fixed;
  top: 64px;
  right: 0;
  padding: 15px;
  z-index: 9;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${props => props.theme.breakPoints.breakpointSm}) {
    width: 400px;
  }

  @media (min-width: ${props => props.theme.breakPoints.breakpointMd}) {
    width: 600px;
  }
`;

const Notification = styled.div`
  flex: 1 1 auto;
`;

const Notifications = ({notifications, removeNotification}) => {
  const pageNotifications = notifications[getLocation().pathname];

  if (!(pageNotifications && pageNotifications.length)) {
    return null;
  }
  return (
    <NotificationsContainer>
      {map(pageNotifications, ({type, heading, message, message2, id}) => {
        return (
          <Notification key={id} className={`alert alert-${type}`} role="alert">
            <button
              type="button"
              className="close"
              onClick={() => removeNotification({id})}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            {heading && <header className="h4">{heading}</header>}
            {isArray(message) ? map(message, msg => <p key={msg}>{msg}</p>) : message}

            {message2 && (
              <Fragment>
                <hr />
                <p className="mb-0">{message2}</p>
              </Fragment>
            )}
          </Notification>
        );
      })}
    </NotificationsContainer>
  );
};

Notifications.propTypes = {
  notifications: PropTypes.object.isRequired,
  removeNotification: PropTypes.func.isRequired,
};

export default connect(
  state => ({notifications: state.notifications}),
  actions
)(Notifications);
