import React from 'react';
import Copyright from 'components/copyright';
import Button from 'components/button/button';
import {HEADER_BG_COLOR as bgColor, HERO_COLOR as color} from 'assets/theme';
import styled, {css} from 'styled-components';
import {darken} from 'polished';
import map from 'lodash/map';
import {getLocation} from 'utils';

const anchorStyles = css`
  color: ${props => props.theme.colors.vividAuburn};
  border-bottom: 1px dotted ${props => props.theme.colors.vividAuburn};
  &:hover {
    color: ${props => darken(0.5, props.theme.colors.vividAuburn)};
    border-bottom: 1px dotted ${props => darken(0.5, props.theme.colors.vividAuburn)};
  }
`;

const Root = styled.footer`
  background-color: ${bgColor};
  font-size: 12px;
  color: ${color};
  border-top: 1px solid #f1f2f3;
  padding: 15px 30px;

  p {
    margin: 0;
  }

  a {
    ${anchorStyles};
  }
`;

const NavItem = styled.li`
  display: inline-flex;
  align-items: center;
  margin-left: 0 !important;

  &::after {
    content: '\\2022';
    vertical-align: middle;
    color: #616a78;
    opacity: 0.4;
    cursor: default;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }
`;

const NavItemLink = styled(Button)`
  ${anchorStyles};
  line-height: normal;
  padding: 0 4px;
  margin-left: 8px;
  margin-right: 8px;

  ${props =>
    props.selected &&
    css`
      font-weight: bold;
    `};
`;

const routes = {
  Home: '/',
  About: '/about',
  Blog: '/blog',
  Contact: '/contact',
  Theme: '/theme',
  Pomodoro: '/pomodoro-timer',
  // Demo: '/demo',
};

const PublicFooter = () => {
  return (
    <Root>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <p className="text-center text-md-left">
              <Copyright />
            </p>
          </div>
          <div className="col-md-6">
            <ul className="nav nav-primary nav-dotted nav-dot-separated justify-content-center justify-content-md-end">
              {map(routes, (to, label) => (
                <NavItem key={label}>
                  <NavItemLink to={to} selected={getLocation().pathname === to}>
                    {label}
                  </NavItemLink>
                </NavItem>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Root>
  );
};

export default PublicFooter;
